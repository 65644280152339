import React from "react";
import { Container, Image, Row } from "react-bootstrap";
import FristFact from "../../../img/topic1.png";
import "./TopicSeeMore.css";

const firststtopic = () => {
  return (
    <Container>
      <Row>
        <h2>Oxygen has a colour</h2>
        <h4> - Reader's Digest</h4>
        <p>
          As a gas, oxygen is odourless and colourless. In its liquid and solid
          forms, however, it looks pale blue.
        </p>
        <div style={{ width: "100%" }}>
          <Image alt="" src={FristFact} className="imageWater" />
        </div>
      </Row>
    </Container>
  );
};

export default firststtopic;
